<template>
  <v-app id="inspire">
    <div class="app-context">
      <v-app-bar
        absolute
        color="#fcb69f"
        dark
        shrink-on-scroll
        src="https://picsum.photos/1920/1080?random"
      >
        <template v-slot:img="{ props }">
          <v-img
            v-bind="props"
            gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
          ></v-img>
        </template>

        <div class="logo" style="margin : auto; width : 1000px">
          <v-row>
            <v-col cols="12" md="2"
              ><img class="header_logo" src="@/assets/images/logo_default.svg"
            /></v-col>
            <v-col cols="12" md="8"
              ><h1
                class="couponTitle"
                style="font-size : 32px; position : relative; top : 3px; margin-left : 20px"
              >
                쿠폰 상세정보
              </h1></v-col
            >
          </v-row>
        </div>

        <v-spacer></v-spacer>
      </v-app-bar>
      <div style="margin : 150px 20px 0 20px">
        <div style="position: relative; width: 80%; left: 8%">
          <div
            
            style="width: 100%; text-align: center; font-size: 32px; margin: 10px"
          >
            <span class="couponTitle">쿠폰 상세정보</span>

          </div>
          <v-container>
            <div class="row">
              <div>
                <v-text-field
                class="cponId"
                label="쿠폰번호(8자 이내)"
                :rules="rules"
                hide-details="auto"
                style="width : 700px;"
                :value="this.couponParam.cponId"
                readonly="readonly"
              ></v-text-field>
              </div>
            </div>
            <div class="row">
              <div>
                <v-text-field
                class="cponNm"
                id="readonly1"
                label="쿠폰명(50자 이내)"
                :rules="rules"
                hide-details="auto"
                style="width : 700px; margin : 20px 0 0 0;"
                :value="this.couponParam.cponNm"
                @change="cponNmSave()"
                readonly="readonly"
              ></v-text-field>
              </div>
            </div>
            <div class="row">
              <div>
                <v-text-field
                class="publDt"
                id="readonly2"
                label="발행일자(8자 이내)"
                :rules="rules"
                hide-details="auto"
                style="width : 700px; margin : 20px 0 0 0;"
                @change="publDtSave()"
                :value="this.couponParam.publDt"
                readonly="readonly"
                ></v-text-field>
              </div>
            </div>
            <div class="row">
              <div>
                <v-text-field
                class="prodDvcd"
                id="readonly3"
                label="적용분류코드"
                :rules="rules"
                hide-details="auto"
                style="width : 700px; margin : 20px 0 0 0;"
                :value="this.couponParam.prodDvcd == 1 ? '1: 상품' : this.couponParam.prodDvcd == 2 ? '2: 진료의뢰서' : ''"
                readonly="readonly"
                ></v-text-field>
              </div>
            </div>
            <div class="row" style="width : 500px;">
              <v-select
              class="selectedProdDvcd"
              :items="items"
              item-value="id" 
              item-text="name"
              v-model="selectedItems"
              style="margin : 30px 0 -20px 0;"
              outlined
              label="적용분류코드"
              item-color="blue"
              color = "blue"
              @change="changeProdCd(selectedItems)"
              >
              </v-select>
            </div>
            <!--
            <div class="row">
              <div>
                <v-text-field
                class="prodNm"
                label="적용상품명"
                :rules="rules"
                hide-details="auto"
                style="width : 700px; margin : 20px 0 0 0;"
                :value="this.prodParam.prodNm"
                readonly="readonly"
                ></v-text-field>
              </div>
            </div>
            -->
            <div class="row">
              <div>
                <v-text-field
                class="prodPrice"
                label="쿠폰금액(상품금액)"
                hide-details="auto"
                style="width: 700px; margin: 20px 0 0px 0;"
                :value="this.couponParam.cponAmt.toString().replace('-','')"
                readonly="readonly"
                ></v-text-field>
              </div>
            </div>
            <div class="row">
              <div class="validPeriod" style="font-weight: bold; font-size: 18px; margin-top : 20px;">
                쿠폰 유효 기간
              </div>
            </div>
          
            <span class="detailPeriod" style="width : 50%; display: flex; margin: 20px 0 30px -12px;">
              <v-menu
                ref="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <input
                    class="couponValidStr"
                    ref="dateInputFirst"
                    type="text"
                    style="width : 40%; text-align: center; border:1px solid #000"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    disabled="disabled"
                  />
                </template>
                <v-date-picker
                  calss="picker1"
                  color="#146F83"
                  header-color="#146F83"
                  v-model="picker_first"
                  locale="kr"
                  no-title
                  @input="menu1 = false"
                >
                </v-date-picker>
              </v-menu>
              <span style="width : 20%; font-size: 30px; text-align: center;">~</span>
              <v-menu
                ref="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <input
                    class="couponValidEnd"
                    ref="dateInputSecond"
                    type="text"
                    style="width : 40%; text-align: center; border:1px solid #000"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    disabled="disabled"
                  />
                </template>
                <v-date-picker
                  calss="picker1"
                  color="#146F83"
                  header-color="#146F83"
                  v-model="picker_second"
                  locale="kr"
                  no-title
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </span>

            <span class="modifyPeriod" style="width : 50%; display: flex; margin: 20px 0 30px -12px;">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <input
                    class="modifyCouponValidStr"
                    ref="dateInputFirst"
                    type="text"
                    style="width : 40%; text-align: center; border:1px solid #000"
                    v-model="conditional_date_array[0]"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click="pickerOffset(0)"
                  />
                </template>
                <v-date-picker
                  calss="picker1"
                  color="#146F83"
                  header-color="#146F83"
                  v-model="picker_first"
                  @change="click_picker(0)"
                  locale="kr"
                  no-title
                  @input="menu1 = false"
                >
                </v-date-picker>
              </v-menu>
              <span style="width : 20%; font-size: 30px; text-align: center;">~</span>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <input
                    class="modifyCouponValidEnd"
                    ref="dateInputSecond"
                    type="text"
                    style="width : 40%; text-align: center; border:1px solid #000"
                    v-model="conditional_date_array[1]"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click="pickerOffset(1)"
                  />
                </template>
                <v-date-picker
                  calss="picker1"
                  color="#146F83"
                  header-color="#146F83"
                  v-model="picker_second"
                  @change="click_picker(1)"
                  locale="kr"
                  no-title
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </span>
            <div class="row">
              <div>
                <v-text-field
                class="useYn"
                label="사용, 만료여부"
                :rules="rules"
                hide-details="auto"
                style="width : 700px; margin : 20px 0 0 0;"
                :value="this.couponParam.useYn == 'Y' ? '사용가능': '만료'"
                readonly="readonly"
                ></v-text-field>
              </div>
            </div>
            <!--
            <div class="row">
              <div>
                <v-text-field
                id="readonly4"
                class="drId"
                label="사용자(아이디)"
                hide-details="auto"
                style="width: 700px; margin: 20px 0 0px 0;"
                :value="this.couponParam.drId ? this.couponParam.drId : '미등록'"
                readonly="readonly"
                @change="drIdSave()"
                ></v-text-field>
              </div>
            </div>
            <div class="row">
              <div>
                <v-text-field
                id="readonly5"
                class="regiId"
                label="(사용자)쿠폰 등록번호(14자 이내)"
                hide-details="auto"
                style="width: 700px; margin: 20px 0 0px 0;"
                :value="this.couponParam.regiId ? this.couponParam.regiId : '미등록(직접 사용자에게 부여시 쿠폰 등록번호가 자동으로 등록됩니다.)'"
                readonly="readonly"
                ></v-text-field>
              </div>
            </div>
            <div class="row">
              <div>
                <v-text-field
                id="readonly6"
                class="regDt"
                label="쿠폰 등록일자(8자 이내)"
                hide-details="auto"
                style="width: 700px; margin: 20px 0px 0;"
                :value="this.couponParam.regDt ? this.couponParam.regDt : '미등록'"
                readonly="readonly"
                @change="regDtSave()"
                ></v-text-field>
              </div>
            </div>
            <div class="row">
              <div>
                <v-text-field
                id="readonly7"
                class="useDt"
                label="쿠폰 사용일자(8자 이내)"
                hide-details="auto"
                style="width: 700px; margin: 20px 0 0px 0;"
                :value="this.couponParam.useDt ? this.couponParam.useDt : '미사용(수정 시 쿠폰을 사용하도록 하려면 내용을 지우거나 미사용으로 두시기 바랍니다.)'"
                readonly="readonly"
                @change="useDtSave()"
                ></v-text-field>
              </div>
            </div>
            -->
            <div class="row" style="margin-top : 35px;">
              <!--
              <input type="file" name="profile_pt" id="profile_pt" @change="previewImage(this,'View_area')">
              <div id='View_area' style='position:relative; margin-top : 20px; max-width: 800px; max-height:1000px; color: black; border: 0px solid black; dispaly: inline; clear : both;'
              >
              </div>
              -->
              <div class="modifyInformation" 
              style="position : relative; left : 10px; text-align : center; width : 800px; color : blue;"
              >
              파란색 글씨만 수정이 가능합니다.(쿠폰명, 발행일자, 적용분류코드, 쿠폰 유효 기간)
              </div>
              <!--
              <div class="modifyInformation" 
              style="position : relative; left : 10px; top : 10px; text-align : center; width : 800px; "
              >
              미등록된 쿠폰을 직접 사용자에게 부여하려면 사용자, 쿠폰 등록일자를 적어주세요.
              </div>
              !-->
              <div class="row add-btn" style="margin-top : 40px;">
                  <div
                      style="width: 100%; text-align: center; font-size: 32px; margin: 10px"
                      >
                      <v-btn
                      class="primary white--text modifyBtn"
                      outlined
                      tile
                      dense
                      large
                      style="margin: 0 20px 0 0px; width: 250px; height: 70px;"
                      ><v-icon size="30" style="margin-right: 15px;">mdi-update</v-icon>
                      <span class="modifyBtnText" style="font-size : 25px; line-height : 10px;">쿠폰 수정하기</span>
                      </v-btn>
                      <v-btn
                      class="error white--text deleteBtn"
                      outlined
                      tile
                      dense
                      large
                      style="margin: 0 0px 0 0px; width: 250px; height: 70px;"
                      @click="deleteCoupon()"
                      ><v-icon size="30" style="margin-right: 15px;">mdi-update</v-icon>
                      <span class="deleteBtnText" style="font-size : 25px; line-height : 10px;">쿠폰 폐기하기</span>
                      </v-btn>
                  </div>
                  <div
                      class="modifyBtnApply"
                      style="width: 100%; text-align: center; font-size: 32px; margin: 10px"
                      >
                      <v-btn
                      class="primary white--text"
                      outlined
                      tile
                      dense
                      large
                      style="margin: 0 0px 0 0px; width: 350px; height: 70px;"
                      @click="updateCoupon()"
                      ><v-icon size="35" style="margin-right: 15px;">mdi-update</v-icon>
                      <span class="modifyBtnText" style="font-size : 35px; line-height : 10px;">수정 적용하기</span>
                      </v-btn>
                  </div>
              </div>
            </div>
          </v-container>
          <div style="height: 100px" />
        </div>
      </div>
      <default-popup v-if="checkPopup" :popupSet="popupSet" />
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import ImageViewer from "@/components/ImageViewer";
import DefaultPopup from "@/components/modal/DefaultPopup";
export default {
  components: { ImageViewer,DefaultPopup},
  data() {
    return {
      photo: "",
      couponParam : {
        cponId: '',
        publDt: '',
        prodDvcd: '',
        cponNm: '',
        cponAmt: '',
        validStrDt: '',
        validEndDt: '',
        useYn: '',
        creId: '',
        updId: '',
        creDt: '',
        updDt: '',
        regiId: '',
        drId: '',
        regDt: '',
        useDt: '',
      },
      prodParam : {
        aplyEndDt: '',
        aplyStrDt: '',
        attchId: '',
        isDeletedAttchId: '',
        price: '',
        prodCd: '',
        prodDesc: '',
        prodNm: '',
        prodNo: '',
        unitNm: '',
        useYn: '',
      },
      prodList : [],
      prodNmList : [],
      items: [
        {name: "1: 상품", id : 1},
        {name: "2: 진료의뢰서", id : 2}
      ],
      selectedItems: {name: "1: 상품", id : 1},
      popupSet: {},
      foducs : {},
      showImage: false,
      mapLocation: {
        posX: 37.578801,
        posY: 126.966441,
      },
      conditional_date_array: [ new Date().toISOString().substr(0, 10).replace('T', ' ').replaceAll('-',""), "99991231"],
      picker_first: "",
      picker_first_offset: [0, 0],
      picker_second: "",
      picker_second_offset: [0, 0],
      menu1: false,
      menu2: false,
      rules: [
        value => !!value || '최소 2자 이상 입력해주세요.',
        value => (value && value.length >= 2) || '최소 2자 이상 입력해주세요',
      ],
      listStatus: {
        totalCount: null, // 조회하여 가져온 총 데이터의 수
        dataOnPage: 1000, // 한 페이지에 내려줄 데이터의 수
        pageCount: 1000, // 한 페이지에 1~5까지 보여준다.
        totalPage: null, // 페이지네이션에 출력한 총 페이지의 수
        currentPage: 1, // 현재 페이지
        prev: null,
        next: null,
        first: null,
        last: null,
        currentPaginationList: [],
        /**
         * totalCount: 22
         * currentPage: 1
         * totalPage: 3
         * pageGroup: 1
         * last: 3
         * first: -1
         * next: 4
         * prev: -2
         */
      },
    };
  },
  created() {
    this.getCoupon();
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    ...mapGetters("join", ["GET_BASIC"]),
    ...mapGetters("promotion", ["GET_PROMOTION"]),
    ...mapGetters("admin",["GET_COUPON_DATA","GET_PROD_DATA","GET_PROD_LIST"]),
  },
  mounted(){
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
    $(".modifyPeriod").hide();
    $(".modifyInformation").hide();
    $(".modifyBtnApply").hide();
    $(".selectedProdDvcd").hide();
    $(".modifyBtn").one("click",function(){
      document.getElementById('readonly1').readOnly = false;
      document.getElementById('readonly2').readOnly = false;
      //document.getElementById('readonly4').readOnly = false;
      //document.getElementById('readonly6').readOnly = false;
      //document.getElementById('readonly7').readOnly = false;
      $("#readonly1").css("color","blue");
      $("#readonly2").css("color","blue");
      $(".validPeriod").css("color","blue");
      $("#readonly4").css("color","blue");
      $("#readonly6").css("color","blue");
      $("#readonly7").css("color","blue");
      $(".detailPeriod").hide();
      $(".modifyPeriod").show();
      $(".modifyCouponValidStr").val($(".couponValidStr").val());
      $(".modifyCouponValidEnd").val($(".couponValidEnd").val());
      $(".modifyBtnText").text("수정 완료");
      $(".modifyInformation").show();
      $(".modifyBtn").hide();
      $(".modifyBtnApply").show();
      $(".selectedProdDvcd").show();
      $(".prodDvcd").hide();
      $(".deleteBtn").hide();
    });
  }
  ,
  methods: {
    ...mapMutations("basic", ["SET_POPUP", "SET_CHILD_LOADER"]),
    ...mapActions("join", ["FETCH_BASIC_TARGET_UN_AUTH"]),
    ...mapActions("promotion", ["FETCH_PROMOTION_TARGET_UN_AUTH"]),
    ...mapActions("admin",["BRING_COUPON_DATA","UPDATE_COUPON_DATA","BRING_PROD_DATA","BRING_PROD_LIST"]),
    viewPhoto(src) {
      this.photo = src;
      //console.log(this.photo);
      this.showImage = true;
    },
    async getCoupon(){
        if(this.$route.params.code === null 
        || this.$route.params.code === undefined)
        this.$route.params.code = "";

        var cponIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{8,14}$/;

        if(!cponIdBoo.test(this.$route.params.code)){
          this.SET_POPUP(true);
          this.popupSet.title = "존재하지 않는 쿠폰번호";
          this.popupSet.content = "존재하지 않는 쿠폰번호입니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }
        //console.log("this.$route.params.code : ",this.$route.params.code);
        await this.BRING_COUPON_DATA(this.$route.params.code).then(
          () => {
           this.couponParam = this.GET_COUPON_DATA;
           //console.log(this.GET_COUPON_DATA);
          }
        );
        //console.log("couponParam",this.couponParam);
        $(".couponValidStr").val(this.couponParam.validStrDt);
        $(".couponValidEnd").val(this.couponParam.validEndDt);
        this.conditional_date_array[0]=this.couponParam.validStrDt;
        this.conditional_date_array[1]=this.couponParam.validEndDt;

        /*
        await this.BRING_PROD_DATA(this.couponParam.prodCd).then(
          () => {
            this.prodParam = this.GET_PROD_DATA;
            console.log(this.prodParam);


            var initialItem = {name: this.GET_PROD_DATA.prodNm, id: this.GET_PROD_DATA.prodCd};

            //this.items= [this.GET_PROD_DATA.prodNm];
            //this.selectedItems = this.GET_PROD_DATA.prodNm;

            //this.items= [initialItem];
            //this.selectedItems = this.GET_PROD_DATA.prodCd;
          }
        );
        */
      /*
      const searchOpt = {
        pageNo: 1,
        recordsPerPage: this.listStatus.dataOnPage,
      };
      
        await this.BRING_PROD_LIST(searchOpt).then(
          () => {
            this.prodList = this.GET_PROD_LIST;
            console.log(this.prodList.list);

            for(var a = 0; this.prodList.list.length > a; a++){
              //console.log(this.prodList.list[a].prodNm);
              //this.items.push(this.prodList.list[a].prodNm);
              var item = {name: this.prodList.list[a].prodNm, id : this.prodList.list[a].prodCd};
              this.items.push(item);
            }
            console.log(this.items);
            
          }
        );
        */
        
    },
    async updateCoupon(){
      //console.log(this.couponParam);
      //유효성 검사 

      //1. 쿠폰명

      if(this.couponParam.cponNm === null 
        || this.couponParam.cponNm === undefined)
        this.couponParam.cponNm = "";

      if(this.couponParam.cponNm.length < 2 || 
          this.couponParam.cponNm.length > 100){
        this.SET_POPUP(true);
        this.popupSet.title = "쿠폰명 작성 필요";
        this.popupSet.content = "쿠폰명이 작성되지 않았습니다. 쿠폰명을 입력해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //2. 발행일자
      if(this.couponParam.publDt === null 
      || this.couponParam.publDt === undefined)
      this.couponParam.publDt = "";

      var publDtdBoo = /^[0-9]{8,8}$/;

      if(!publDtdBoo.test(this.couponParam.publDt)){
       this.SET_POPUP(true);
        this.popupSet.title = "발행일자 확인";
        this.popupSet.content = "발행일자가 정상적으로 작성되지 않았습니다. 발행일자를 확인해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //3. 쿠폰 유효기간 시작일자
      if(this.couponParam.validStrDt === null 
      || this.couponParam.validStrDt === undefined)
      this.couponParam.validStrDt = "";

      var validStrDtdBoo = /^[0-9]{8,8}$/;

      if(!validStrDtdBoo.test(this.couponParam.validStrDt)){
        this.SET_POPUP(true);
        this.popupSet.title = "쿠폰 유효기간 확인";
        this.popupSet.content = "유효기간이 정상적으로 작성되지 않았습니다. 유효기간을 확인해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //4. 쿠폰 유효기간 종료일자
      if(this.couponParam.validEndDt === null 
      || this.couponParam.validEndDt === undefined)
      this.couponParam.validEndDt = "";

      var validEndDtBoo = /^[0-9]{8,8}$/;

      if(!validEndDtBoo.test(this.couponParam.validEndDt)){
        this.SET_POPUP(true);
        this.popupSet.title = "쿠폰 유효기간 확인";
        this.popupSet.content = "유효기간이 정상적으로 작성되지 않았습니다. 유효기간을 확인해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      this.couponParam.cponAmt = "-"+this.couponParam.cponAmt.toString().replace("-","");

      //5. 금액
      if(this.couponParam.cponAmt === null 
        || this.couponParam.cponAmt === undefined)
        this.couponParam.cponAmt = "";
      
      var cponAmtBoo = /^[0-9]{1,7}$/;

      if(!cponAmtBoo.test(this.couponParam.cponAmt.toString().replace("-",""))){
        this.SET_POPUP(true);
        this.popupSet.title = "쿠폰 유효기간 확인";
        this.popupSet.content = "유효기간이 정상적으로 작성되지 않았습니다. 유효기간을 확인해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      /*
      if($('drId').val() === '미등록'){
        this.couponParam.drId == '';
      }
      if($('regiId').val() === '미등록(직접 사용자에게 부여시 쿠폰 등록번호가 자동으로 등록됩니다.)'){
        this.couponParam.regiId == '';
      }
      if($('regDt').val() === '미등록'){
        this.couponParam.regDt == '';
      }
      if($('useDt').val() === '미사용(쿠폰을 사용하도록 하려면 내용을 지우거나 미사용으로 두시기 바랍니다.)'){
        this.couponParam.useDt == '';
      }
      */

      const transData = new FormData();
      const transedCouponParam = JSON.stringify(this.couponParam);
      transData.append("couponParam", transedCouponParam);
      try {
        await this.UPDATE_COUPON_DATA(transData).then((data) => {
           if(data > 900){
            this.SET_POPUP(true);
            this.popupSet.title = "쿠폰 수정 실패";
            this.popupSet.content = "쿠폰을 수정하는데 실패하였습니다. 내용을 확인해주세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return
          }

          this.SET_POPUP(true);
          this.popupSet.title = "쿠폰 수정 완료";
          this.popupSet.content = "쿠폰 수정을 완료 했습니다";
          this.popupSet.popType = "refund";
          this.popupSet.confirmBtnText = undefined;
          this.popupSet.cancelBtnText = "확인";
        });
      } catch (error) {
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "쿠폰 수정 실패";
          this.popupSet.content = "쿠폰을 수정하는데 실패하였습니다. 내용을 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    deleteCoupon(){
      //console.log("this.couponParam : " ,this.couponParam);
        this.SET_POPUP(true);
        this.popupSet.title = "쿠폰 폐기";
        this.popupSet.content = "쿠폰을 폐기하시겠습니까? \n 확인 버튼을 누르면 삭제 및 리스트 페이지로 이동됩니다.";
        this.popupSet.popType = "custom_coupon_delete";
        this.popupSet.confirmBtnText = "확인";
        this.popupSet.cancelBtnText = "취소";
        this.popupSet.destination = true;
        this.popupSet.couponParam = this.couponParam;
        this.popupSet.nextLink = "/v/adminMain/10";
    },
    pickerOffset(picker) {
      if (picker === 0) {
        //console.log(this.$refs.dateInputFirst);
        const clientRect = this.$refs.dateInputFirst.getBoundingClientRect();
        //console.log(clientRect.top);
        //console.log(clientRect.left);

        this.picker_first_offset = [clientRect.left, clientRect.top];

        //console.log(this.$refs.menu1.styles);
      } else if (picker === 1) {
        //console.log(this.$refs.dateInputSecond);
        const clientRect = this.$refs.dateInputSecond.getBoundingClientRect();
        //console.log(clientRect.top);
        //console.log(clientRect.left);
        this.picker_second_offset = [clientRect.left, clientRect.top];
      }
    },
    async clickSearch() {
      //console.log(this.conditional_date_array);
      if (this.type === "전체") {
        this.conditional_condition = 0;
      } else if (this.type === "회신 완료") {
        this.conditional_condition = 1;
      } else if (this.type === "내원 완료") {
        this.conditional_condition = 2;
      } else if (this.type === "되의뢰 완료") {
        this.conditional_condition = 3;
      }

      //console.log(this.conditional_condition);
      /*
      console.log(
        this.$moment(this.conditional_date_array[1]).isAfter(
          this.conditional_date_array[0]
        )
      );
      */
      if (
        this.conditional_date_array[0] === "전체" &&
        this.conditional_date_array[1] === "전체"
      ) {
        this.conditional_date_array[0] = null;
        this.conditional_date_array[1] = null;
        this.reload();
      } else if (
        this.conditional_date_array[0] !== undefined &&
        this.conditional_date_array[0] !== null &&
        this.conditional_date_array[1] !== undefined &&
        this.conditional_date_array[1] !== null
      ) {
        if (
          this.$moment(this.conditional_date_array[1]).isAfter(
            this.conditional_date_array[0]
          )
        ) {
          this.reload();
        } else {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content =
            "검색조건이 알맞지 않습니다. 다시한번 확인해 주세요";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content =
          "검색조건이 알맞지 않습니다. 다시한번 확인해 주세요";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }
    },
    click_picker(str) {
      if (str === 0) {
        //console.log(this.picker_first);
        const [year, month, day] = this.picker_first.split("-");
        //console.log(`${year}${month}${day}`);
        this.conditional_date_array[0] = `${year}${month}${day}`;
        this.couponParam.validStrDt = `${year}${month}${day}`;
        //console.log("현재 validStrDt : ", this.couponParam.validStrDt);
        //console.log("현재 validEndDt : ", this.couponParam.validEndDt);
      } else if (str === 1) {
        //console.log(this.picker_second);
        const [year, month, day] = this.picker_second.split("-");
        //console.log(`${year}${month}${day}`);
        this.conditional_date_array[1] = `${year}${month}${day}`;
        this.couponParam.validEndDt = `${year}${month}${day}`;
        //console.log("현재 validEndDt : ", this.couponParam.validEndDt);
      }
    },
    async conditional_date(type) {
      if (type === "all") {
        this.conditional_date_array = ["전체", "전체"];
      } else if (type === "month") {
        this.conditional_date_array = [
          this.$moment()
            .subtract(0, "M")
            .format("YYYYMM") + "01",
          this.$moment().format("YYYYMMDD"),
        ];
      } else if (type === "month_back") {
        this.conditional_date_array = [
          this.$moment()
            .subtract(1, "M")
            .format("YYYYMMDD"),
          this.$moment().format("YYYYMMDD"),
        ];
      } else if (type === "3month_back") {
        this.conditional_date_array = [
          this.$moment()
            .subtract(3, "M")
            .format("YYYYMMDD"),
          this.$moment().format("YYYYMMDD"),
        ];
      }
    },
    publDtSave(){
      this.couponParam.publDt = event.target.value;
      //console.log(this.couponParam.publDt);
    },
    /*
    prodCdSave(){
      this.couponParam.prodCd = event.target.value;
      console.log(this.couponParam.prodCd);
    },
    */
    regDtSave(){
      this.couponParam.regDt = event.target.value;
      //console.log(this.couponParam.regDt);
    },
    useDtSave(){
      this.couponParam.useDt = event.target.value;
      //console.log(this.couponParam.useDt);
    },
    drIdSave(){
      this.couponParam.drId = event.target.value;
      //console.log(this.couponParam.drId);
    },
    cponNmSave(){
      this.couponParam.cponNm = event.target.value;
      //console.log(this.couponParam.drId);
    },
    changeProdCd(selectedItems){
      //console.log(selectedItems);
      this.couponParam.prodDvcd = selectedItems;
       $(".prodDvcd").val(selectedItems);
       //console.log("바뀐 분류코드 : " + this.couponParam.prodDvcd);
    }
  }, 
  destroyed() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "on",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
};
</script>

<style>
.img-viewer{
    top : -200px !important;
}
.origin{
    position: relative !important;
    top : 340px !important;
    left: 90px !important;
}
</style>
